import React, { useState, useEffect } from "react";
import {
    Container
} from "reactstrap";
import BackgroundImage from 'gatsby-background-image';
import { getColor, createUrl, checkCorporateEmail } from "../utils/universalHelperFunctions";
import AnimatedText from "../components/AnimatedText";
import "../styles/career-banner.css";

const CareerBanner = (props) => {
    const [sliderHeight, setSliderHeight] = useState();
    const [bgImage, setBgImage] = useState("");
    const [bgImageName, setBgImageName] = useState("");
    const { input } = props;
    const { primary, items } = input;
    console.log("primary in banner", primary);
    // useEffect(() => {
        //window.addEventListener('resize', (e) => { this.onResize();});
    // }, []);

    useEffect(() => {
        const updateBgImage = () => {
          const width = window.innerWidth;
          if (width >= 1024) {
            setBgImage(`url(${primary.banner_image.url})`);
            setBgImageName("desktop-banner");
          } else if (width >= 767) {
            setBgImageName("tablet-banner");
            setBgImage(`url(${primary.banner_image.Tablet.url})`);
          } else {
            setBgImageName("mobile-banner");
            setBgImage(`url(${primary.banner_image.MobileHDPI.url})`);
          }
        };     
        updateBgImage();
    
      }, []);
    console.log("banner items", props);
    const carouselTextClass = window.location.pathname === '/jobs-for-freshers/' || '/jobs-for-experienced/';
    const sources = [];
    if (primary.banner_image?.MobileHDPI && primary.banner_image?.MobileHDPI?.localFile) {
        sources.push({
            ...primary.banner_image?.MobileHDPI?.localFile?.childImageSharp?.fluid,
            media: `(max-width: 767px)`
        })
    }
    if (primary.banner_image.Tablet && primary.banner_image.Tablet.localFile) {
        sources.push({
            ...primary.banner_image?.Tablet?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 768px) and (max-width: 1023px)`,
        });
    }
    if (primary.banner_image?.Desktop && primary.banner_image?.Desktop?.localFile) {
        sources.push({
            ...primary.banner_image?.Desktop?.localFile?.childImageSharp?.fluid,
            media: `(min-width: 1024px)`,
        });
    }

    return (
        <React.Fragment key={primary.primary_heading.text}>
            {/* <BackgroundImage */}
            <div
            // Tag="div"
                // fluid={sources}
                className={`sliderImageHeight banner-background-image career-banner-image ${bgImageName}`}
                style={{position: 'inherit',backgroundImage: bgImage, backgroundRepeat: 'no-repeat', backgroundSize: (window.innerWidth >= 767 && window.innerWidth <= 1400) ? 'contain' : 'cover',  }}
            >
                {/* <div className={`${props.hero_image_overlay == "true" ? 'image-overlay' : ''}`} > */}
                <div className={`career-carousel-media-overlay ${window.location.pathname === "/lets-co-create/" || window.location.pathname === "/"  ? 'justify-content-start career-carousel-media-overlay-homepage' : ''} ${window.location.pathname === "/jobs-for-freshers/" || window.location.pathname === "/jobs-for-experienced/" ? 'career-carousel-media-overlay-jobs' : ''}`} 
                // style={props.isOverlay ? { backgroundColor: 'black' } : {}}
                >
                    {/* <div className="carousel-label-wrapper">
                                    <h6 className="carousel-label">{primary.primary_heading.text}</h6>
                                </div> */}
                    <div className="row align-items-center" style={{margin:"auto", width:"max-content", display: (window.location.pathname === "/life-at-gspann" || window.location.pathname === "/life-at-gspann/") ? "block" : "flex" }} >
                        <div className={`${(window.location.pathname === "/lets-co-create" || window.location.pathname === "/lets-co-create/") ? "col-md-6 primary-text" : "col-md-12"} no-padding`}>
                            <div className={`carousel-heading ${(window.location.pathname === "/lets-co-create" || window.location.pathname === "/lets-co-create/") ? '' : 'jobs-heading'}`} style={(window.location.pathname === "/lets-co-create" || window.location.pathname === "/lets-co-create/") ? {} : { paddingLeft: "0px", paddingBottom: '0rem', color: 'white' }} dangerouslySetInnerHTML={{ __html: primary.primary_heading.text }}></div>
                        </div>
                        {
                            items[0].animated_text?.text != null ? <div className="col-md-5 no-padding secondary-text ani_txt">
                                <div className={`animated-text px-2`}>
                                    <AnimatedText items={items} />
                                </div>
                            </div> : <></>
                        }
                    </div>
                    <div className="row justify-content-center align-items-center" style={{ display: "block" }} >
                        <div className="col-md-12 no-padding  secondary-text">
                            <div className={`${carouselTextClass ? 'carousel-text-large' : 'carousel-text'}`} dangerouslySetInnerHTML={{ __html: primary.secondary_heading.text }}></div>
                        </div>
                    </div>
                    {primary.mobile_view_heading_1?.text && <div className={`mobile-heading-container pws-mobile ${window.location.pathname === "/life-at-gspann/" ? "life-at-gspann-mobile-heading-container" : ""} ${window.location.pathname === "/problems-we-solve/" ? "pws-mobile-heading-container" : ""} ${window.location.pathname === "/jobs-for-freshers/" ? "jff-mobile-heading-container" : ""} ${window.location.pathname === "/jobs-for-experienced/" ? "jfe-mobile-heading-container" : ""}`} >
                        {primary.mobile_view_heading_1?.text && <div className={`row justify-content-center align-items-center pws-mobile ${window.location.pathname === "/" ? "homepage-hero-heading-mobile" : ""}  ${window.location.pathname === "/" ? "life-at-gspann-hero-heading-mobile" : ""}`} style={{ display: "block" }} >
                            <div className="col-md-12 no-padding heading1-mobile">
                                <div className={`${carouselTextClass ? 'carousel-text-large' : 'carousel-text'} ${window.location.pathname === "/" ? "carousel-text-large-homepage" : ""} ${window.location.pathname === "/life-at-gspann" ? "carousel-text-large-life-at-gspann" : ""}`} dangerouslySetInnerHTML={{ __html: primary.mobile_view_heading_1?.text }}></div>
                            </div>
                        </div>}
                        {primary.mobile_view_heading_2?.text && <div className={`row justify-content-center align-items-center pws-mobile`} style={{ display: "block" }} >
                            <div className="col-md-12 no-padding">
                                <div className={`${carouselTextClass ? 'carousel-text-large' : 'carousel-text'} ${window.location.pathname === "/" ? "carousel-text-large-homepage" : ""}`} dangerouslySetInnerHTML={{ __html: primary.mobile_view_heading_2?.text }}></div>
                            </div>
                        </div>}
                        {primary.mobile_view_heading_3?.text && <div className={`row justify-content-center align-items-center pws-mobile`} style={{ display: "block" }} >
                            <div className="col-md-12 no-padding">
                                <div className={`${carouselTextClass ? 'carousel-text-large' : 'carousel-text'} ${window.location.pathname === "/" ? "carousel-text-large-homepage" : ""}`} dangerouslySetInnerHTML={{ __html: primary.mobile_view_heading_3?.text }}></div>
                            </div>
                        </div>}
                    </div>}
                    {/* <div className="row justify-content-center align-items-center" style={{ display: "block" }} >
                        <div className="col-md-12 no-padding">
                            <div className={`banner-description`} dangerouslySetInnerHTML={{ __html: primary.banner_description?.text }}></div>
                        </div>
                    </div> */}
                    { (items[0].button_one?.text || items[0].button_one?.text) && <div  className="row career-banner-btn-row">
                    <div className="col-md-06 career-banner-btn-col">
                    <a href={items[0].button_one_url?.url} style={{width: '100%'}}>
                    <button className='button career-banner-btn'>{items[0].button_one.text}</button>
                    </a>
                    </div>
                    <div className="col-md-06 career-banner-btn-col">
                    <a href={items[0].button_two_url?.url} style={{width: '100%'}}>
                    <button className='button career-banner-btn'>{items[0].button_two.text}</button>
                    </a>
                    </div>
                    </div>}

                </div>
                {/* </div> */}
                {/* </BackgroundImage> */}
                </div>
                </React.Fragment >
    )
}
export default CareerBanner;
