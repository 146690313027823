import React, { Component, Suspense } from "react";
import PropTypes from "prop-types";
import {
  HomePageCorousel,
  NumbersBanner,
  ListWithIcons,
  CareerDescription,
  JobsCard,
  CareerBanner,
  InlineFormDisplaySection,
  GspannValuesSection,
  DescriptionWithProfiles,
  LatestJobOpenings,
  Positions,
  FormDisplaySection2,
  JobDetails,
  VirtualWalkinDetails,
  WalkinDetails,
  BrandsWeWork,
  WalkinCardsSection,
  WalkinCardList,
  Policies,
  SPattern
} from "slices";
import TestiminialComponentSlice from './../slices/TestimonialSlice';
import SocialMedia from './../slices/SocialMedia';

export default class SliceZone extends Component {
  constructor(props) {
    super(props);
    this.selectedFilter = this.selectedFilter.bind(this);
    this.pageOverViewData = null;
    this.resourceOverviewData = null;
    this.pageHeaderBodyData = null;
    this.pageHeroData = null;
    this.linksData = props.pagesLinksData.concat(props.resourceTilesData);
    this.sortPagesBasedOnREsourceTags =
      this.sortPagesBasedOnREsourceTags.bind(this);
    //this.breadcrumbsDataArray;
    this.pageAdobeDynamicLinks = props.pageDynamicData;

    this.state = {
      resourceTilesData: props.resourceTilesData,
      homePageFeaturedResourceData: props.homePageFeaturedResourceData,
      homepageEventsData: props.homepageEventsData,
      selectedOptions: "",
      searchWord: "",
      searchTag: "",
    };
  }
  selectedFilter(filterList) {
    if (filterList && filterList.length > 0) {
      filterList.forEach((data) => {
        if (data.type === "checkbox") {
          this.setState({
            selectedOptions:
              data.value || JSON.parse(localStorage.getItem("resourceFilter")),
          });
        } else if (data.type === "searchWord") {
          this.setState({
            searchWord:
              data.value || JSON.parse(localStorage.getItem("searchWord")),
          });
        } else if (data.type === "resourceTags") {
          this.setState({
            searchTag:
              data.value || JSON.parse(localStorage.getItem("resourceTag")),
          });
        }
      });
    }
  }
  createTilesObject(resourceTilesData) {
    let resourceTilesViewObject = [];
    if (resourceTilesData && resourceTilesData.length > 0) {
      this.resourceTags = [];
      resourceTilesData.forEach((item, i) => {
        if (item) {
          let itemNode = item.node ? item.node : item.document[0];
          let pagePath = itemNode.data.page_path.text;
          const itemBody = itemNode.data.body;
          const rtvo = {};
          const indexForResourceTag = (obj) =>
            obj.__typename === "PrismicResourceBodyResourceTags";
          const indexForResourceType = (obj) =>
            obj.__typename === "PrismicResourceBodyResourceOverview";
          const indexForThimbnail = (obj) =>
            obj.__typename === "PrismicResourceBodyPageOverview";
          const indexForHeroes = (obj) =>
            obj.__typename === "PrismicCareerHomepageBodyHeroes";
          const indexResourceType = itemBody.findIndex(indexForResourceType);
          const indexThumbnail = itemBody.findIndex(indexForThimbnail);
          const indexResourceTag = itemBody.findIndex(indexForResourceTag);
          const indexHeroes = itemBody.findIndex(indexForHeroes);
          rtvo.pagePath = pagePath;
          rtvo.cardType = "resource-card";
          rtvo.displayName = itemNode.data.resource_display_name.text;
          rtvo.href = `${itemNode.data.page_path.text
            }/${itemNode.data.resource_display_name.text
              .split(" ")
              .join("-")
              .toLowerCase()}`;
          rtvo.pageTitle = itemBody[indexThumbnail].primary.page_title.text;
          rtvo.resourceType = itemBody[indexResourceType].primary.resource_type;
          rtvo.thumbNail = itemBody[indexThumbnail].primary.page_thumbnail;
          rtvo.date = itemBody[indexResourceType].primary.date;
          rtvo.title = itemBody[indexResourceType].primary.title.text;
          rtvo.id = itemNode.id || item;
          if (
            itemBody[indexResourceTag] &&
            itemBody[indexResourceTag].items &&
            itemBody[indexResourceTag].items.length > 0
          ) {
            rtvo.resourceTags = itemBody[indexResourceTag];
            rtvo.resourceTags.items.sort((tagDataA, tagDataB) => {
              if (
                tagDataA.tag_keywords_list.toLowerCase() <
                tagDataB.tag_keywords_list.toLowerCase()
              ) {
                return -1;
              }
              if (
                tagDataA.tag_keywords_list.toLowerCase() >
                tagDataB.tag_keywords_list.toLowerCase()
              ) {
                return 1;
              }
              return 0;
            });
            itemBody[indexResourceTag].items.forEach((item) => {
              const found = this.resourceTags.some(
                (el) => el.tag_keywords_list === item.tag_keywords_list
              );
              if (!found) this.resourceTags.push(item);
            });
          }
          if (
            itemBody[indexHeroes] &&
            itemBody[indexHeroes].items &&
            itemBody[indexHeroes].items.length > 0
          ) {
            rtvo.bannerHeader =
              itemBody[indexHeroes].items[0].hero_description.text;
          }
          resourceTilesViewObject.push(rtvo);
        }
      });
      if (this.resourceTags && this.resourceTags.length > 0) {
        this.resourceTags.sort((tagDataA, tagDataB) => {
          if (
            tagDataA.tag_keywords_list.toLowerCase() <
            tagDataB.tag_keywords_list.toLowerCase()
          ) {
            return -1;
          }
          if (
            tagDataA.tag_keywords_list.toLowerCase() >
            tagDataB.tag_keywords_list.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });
        this.resourceTags = [
          { tag_keywords_list: "All Topics" },
          ...this.resourceTags,
        ];
      }
      return resourceTilesViewObject;
    }
  }

  getCarouselList(values) {
    var carouselList = [];
    carouselList = values.items.map((element) => {
      return element.carousel_card_link;
    });
    return carouselList;
  }

  sortPagesBasedOnREsourceTags(resourceType = "", minResult, maxResult) {
    let finalResourceDataObject = {};
    let resourceTilesViewObject = [];
    resourceTilesViewObject = this.createTilesObject(
      this.state.resourceTilesData
    );
    if (resourceType) {
      resourceTilesViewObject = resourceTilesViewObject.filter((item) =>
        resourceType.includes(item.resourceType)
      );
    }
    let pageOverviewIndx = this.props.allSlices.findIndex(
      (slice) => slice.__typename === "PrismicResourceBodyPageOverview"
    );
    let selectedResource =
      resourceTilesViewObject.filter(
        (item) =>
          item.pageTitle ===
          this.props.allSlices[pageOverviewIndx].primary.page_title.text
      )[0] || {};
    resourceTilesViewObject = resourceTilesViewObject.filter(
      (item) =>
        item.pageTitle !==
        this.props.allSlices[pageOverviewIndx].primary.page_title.text
    );
    resourceTilesViewObject = resourceTilesViewObject.filter(
      (item) => item.pagePath !== "campaign"
    );

    resourceTilesViewObject.sort((tile1, tile2) => {
      var nuOfMatchingTags1 =
        tile1.resourceTags &&
          tile1.resourceTags.items &&
          tile1.resourceTags.items.length > 0
          ? tile1.resourceTags.items.reduce(function (count, tag) {
            let found =
              selectedResource.resourceTags &&
              selectedResource.resourceTags.items &&
              selectedResource.resourceTags.items.some(
                (el) => el.tag_keywords_list === tag.tag_keywords_list
              );
            if (found) count++;
            return count;
          }, 0)
          : 0;
      tile1.count = nuOfMatchingTags1;
      var nuOfMatchingTags2 =
        tile2.resourceTags &&
          tile2.resourceTags.items &&
          tile2.resourceTags.items.length > 0
          ? tile2.resourceTags.items.reduce(function (count, tag) {
            let found =
              selectedResource.resourceTags &&
              selectedResource.resourceTags.items &&
              selectedResource.resourceTags.items.some(
                (el) => el.tag_keywords_list === tag.tag_keywords_list
              );
            if (found) count++;
            return count;
          }, 0)
          : 0;
      tile2.count = nuOfMatchingTags2;
      if (nuOfMatchingTags1 > nuOfMatchingTags2) {
        return -1;
      }
      if (nuOfMatchingTags1 < nuOfMatchingTags2) {
        return 1;
      }
      return 0;
    });

    var revelentResult = [];
    resourceTilesViewObject.forEach((item) => {
      if (revelentResult[item.count] && revelentResult[item.count].length > 0) {
        revelentResult[item.count].push(item);
      } else {
        revelentResult[item.count] = [];
        revelentResult[item.count].push(item);
      }
    });
    let notMatchedData =
      revelentResult[0] && revelentResult[0].length
        ? revelentResult.splice(0, 1)[0]
        : [];
    revelentResult = revelentResult.filter(Array);
    revelentResult.reverse();
    revelentResult.forEach((list) => {
      list.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
    });
    if (revelentResult && revelentResult.length > 0) {
      finalResourceDataObject.items = [];
      for (var i = 0, len = revelentResult.length; i < len; i++) {
        for (var j = 0, len2 = revelentResult[i].length; j < len2; j++) {
          if (finalResourceDataObject.items.length < maxResult) {
            finalResourceDataObject.items.push(revelentResult[i][j]);
          } else {
            break;
          }
        }
      }
      // if(revelentResult[0].length >= minResult && revelentResult[0].length <= maxResult) {
      //   finalResourceDataObject.items = revelentResult[0];
      // }else if(revelentResult[0].length > maxResult) {
      //   finalResourceDataObject.items = revelentResult[0].slice(0, maxResult);
      // } else if(revelentResult[0].length < minResult) {
      //   finalResourceDataObject.items = [];
      //   for (var i=0, len=revelentResult.length; i<len; i++) {
      //     // inner loop applies to sub-arrays
      //     for (var j=0, len2=revelentResult[i].length; j<len2; j++) {
      //       if(finalResourceDataObject.items.length < maxResult) {
      //         finalResourceDataObject.items.push( revelentResult[i][j] );
      //       } else {
      //         break;
      //       }
      //     }
      //   }
      // }
    }
    if (
      finalResourceDataObject &&
      (!finalResourceDataObject.items ||
        finalResourceDataObject.items < minResult)
    ) {
      if (!finalResourceDataObject.items) {
        finalResourceDataObject.items = [];
      }
      notMatchedData.sort((a, b) => {
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      });
      for (var i = 0, len = notMatchedData.length; i < len; i++) {
        if (finalResourceDataObject.items.length < minResult) {
          finalResourceDataObject.items.push(notMatchedData[i]);
        } else {
          break;
        }
      }
    }

    return finalResourceDataObject;
  }

  render() {
    var isWindow = typeof window !== "undefined";
    const { allSlices, pageId } = this.props;
    const extraSlices = [];
    const { homePageFeaturedResourceData, homepageEventsData } = this.state;
    console.log('page id: ', pageId);
    // this section is just for blog type resource page to get the data of blog author
    let authorInd = -1;
    let authorData = {};
    let emailTemplateData = [];
    let resourceOverviewIndx = -1;
    let resourceType = "";
    let resourceTagIndx = -1;
    let resourceTagDetails = {};
    let showInCareerJobsExtraSliceVariable = "";

    if (allSlices && allSlices.length > 0) {
      authorInd = allSlices.findIndex(
        (slice) => slice.__typename === "PrismicResourceBodyAuthor"
      );

      emailTemplateData = allSlices.filter((slice) =>
        [
          "PrismicResourceBodyEmailTemplateLinks",
          "PrismicPagePageContentEmailTemplateLinks",
          "PrismicCareerHomepageBodyEmailTemplateLinks",
          "PrismicHomepagePageContentEmailTemplateLinks",
          "PrismicJobDescriptionPageBodyEmailTemplateLinks",
        ].includes(slice.__typename)
      );

      allSlices &&
        allSlices.map((s) => {
          if (s.slice_type == "breadcrumb") {
            this.breadcrumbsDataArray = s;
          }
        });
    }
    resourceOverviewIndx =
      allSlices &&
      allSlices.findIndex((slice) =>
        "PrismicResourceBodyResourceOverview".includes(slice.__typename)
      );

    if (resourceOverviewIndx !== -1) {
      resourceType =
        allSlices && allSlices[resourceOverviewIndx].primary.resource_type;
    }
    resourceTagIndx =
      allSlices &&
      allSlices.findIndex((slice) =>
        "PrismicResourceBodyResourceTags".includes(slice.__typename)
      );
    if (resourceTagIndx !== -1) {
      resourceTagDetails = allSlices && allSlices[resourceTagIndx];
      resourceTagDetails &&
        resourceTagDetails.items.sort((tagDataA, tagDataB) => {
          if (
            tagDataA.tag_keywords_list.toLowerCase() <
            tagDataB.tag_keywords_list.toLowerCase()
          ) {
            return -1;
          }
          if (
            tagDataA.tag_keywords_list.toLowerCase() >
            tagDataB.tag_keywords_list.toLowerCase()
          ) {
            return 1;
          }
          return 0;
        });
    }
    if (authorInd !== -1) {
      authorData = allSlices[authorInd];
    }
    console.log('allSlices', allSlices);
    console.log('pageId', pageId);
   
    if (pageId === "jobs-for-experienced" || pageId === "jobs-for-freshers") {
      const wakinCardsSlice =  allSlices.find(s => s.slice_type === 'walkin_cards');
      const formAnyJobSlice = allSlices.find(s => s.slice_type === 'form_display_section');
      const anyJobFormSlice =  (
        <FormDisplaySection2
          key={formAnyJobSlice.id}
          input={formAnyJobSlice}
          pageId={pageId}
          pageOverViewData={this.pageOverViewData}
          resourceOverviewData={this.resourceOverviewData}
          pageHeroData={this.pageHeroData}
          emailTemplateData={emailTemplateData}
          resourceType={resourceType}
          pageDisplayName={this.props.pageDisplayName}
          jobID={this.props.jobID}
          // showInCareerJobsExtraSlice={(pageId === "jobs-for-experienced" || pageId === "jobs-for-freshers") ? true : false}
        />
      );
      const positionSlice = (
        <Positions
          key="job-positions"
          emailTemplateData={emailTemplateData}
          pageOverViewData={this.pageOverViewData}
          jobListData={this.props.jobListData}
          pagesLinksData={this.linksData}
        />
      );
      const walkinCards = wakinCardsSlice && (
        <WalkinCardsSection
          key={wakinCardsSlice.id}
          input={wakinCardsSlice}
          pagesLinksData={this.linksData}
        />
      );
      extraSlices.push(positionSlice);
      extraSlices.push(walkinCards);
      extraSlices.push(anyJobFormSlice);
      
    }

    const slice = allSlices
      ? allSlices.map((s) => {
        switch (s.slice_type) {
          case "heroes":
            this.pageHeroData = s;
            return (
              <HomePageCorousel
                key={s.id}
                corouselData={s.items}
                pagesLinksData={this.linksData}
                pageId={s.id}
                breadcrumbsData={this.breadcrumbsDataArray}

                pageType={resourceOverviewIndx !== -1 ? "resources" : ""}
                resourceOverviewData={
                  resourceOverviewIndx !== -1
                    ? allSlices[resourceOverviewIndx]
                    : null
                }
              />
            );
          case "careerdescription":
            return (
              <CareerDescription key={s.id} input={s} />
            )
          case "jobs_cards":
            return (
              <JobsCard key={s.id} input={s} ></JobsCard>
            )
          case "career_banner":
            return (
              <CareerBanner key={s.id} input={s} isOverlay={pageId === 'walkin-events'} ></CareerBanner>
            )
          case "social_media":
            return (
              <SocialMedia key={s.id} input={s} />
            )
          case "s-pattern":
            return (
              <SPattern
                key={s.id}
                patternData={s}
                pagesLinksData={this.linksData}
              />
            );
          case "career_testimonial":
            return (
              <TestiminialComponentSlice
                key={s.id}
                input={s}
              />
            )
            case "gspann_values":
              return (
              <GspannValuesSection key={s.id} input={s} ></GspannValuesSection>
              )
            case "description_with_profiles":
              return (
                <DescriptionWithProfiles  key={s.id} input={s} ></DescriptionWithProfiles>
              )
         
            case "latest_job_openings":
              return (
                <LatestJobOpenings
                key={s.id}
                  input={s}
                  pageId={pageId}
                  pagesLinksData={this.linksData}
                   allJobs={this.props.jobListData}
                   pageOverViewData={this.pageOverViewData}
                  />
              )
          
          case "banner-numbers":
            return (
            <NumbersBanner key={s.id} input={s} />
            )
          case "list_with_icons":
            return (
              <ListWithIcons key={s.id} input={s} />
              )
          //   case "testimonials":
          //     return (
          //       <Testimonial
          //         key={s.id}
          //         input={s}
          //         pagesLinksData={this.linksData}
          //       />
          //     );
          //   case "resource_overview":
          //     this.resourceOverviewData = s;
          //      isNewDesign = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true' ? true: false;
          //     var relatedResources = resourceType && resourceType === "Blog" ? this.sortPagesBasedOnREsourceTags(resourceType, 3, 5) : null;

          //     if(isNewDesign) {
          //       relatedResources = resourceType && resourceType === "Case Study"  ? this.sortPagesBasedOnREsourceTags(resourceType, 2, 3): relatedResources;
          //       relatedResources = resourceType && resourceType === "White Paper" ? this.sortPagesBasedOnREsourceTags(['Case Study', 'White Paper'], 2, 3): relatedResources;
          //     }
          //     return (
          //       <ResourceOverview 
          //         pageId={pageId}
          //         key={s.id}
          //         input={s}
          //         author={authorData}
          //         pagesLinksData={this.linksData}
          //         tagsData={resourceTagDetails.items}
          //         tagInput={resourceTagDetails}
          //         relatedBlogs={relatedResources}//reverting new case study design, uncomment line
          //         pageHeroData={this.pageHeroData}
          //       />
          //     );
          //   // case "form_display_section":
          //   //   return <ResourceForms key={s.id} input={s} />;
            // case "walkin_detail":
            //   return <WalkinDetails key={s.id} input={s} />;
          //   case "page_overview":
          //     this.pageOverViewData = s;

          //     break;
            case "form_display_section":
              return pageId !== "jobs-for-freshers" && pageId !==  "jobs-for-experienced" ? 
                <FormDisplaySection2
                  key={s.id}
                  input={s}
                  pageId={pageId}
                  pageOverViewData={this.pageOverViewData}
                  resourceOverviewData={this.resourceOverviewData}
                  pageHeroData={this.pageHeroData}
                  emailTemplateData={emailTemplateData}
                  resourceType={resourceType}
                  pageDisplayName={this.props.pageDisplayName}
                  jobID={this.props.jobID}
                  // showInCareerJobsExtraSlice={(pageId !== "jobs-for-experienced" && pageId !== "jobs-for-freshers") ? true : false}
                />
              : <></>
    
          //   case "award-and-partnerships":
          //     return <ContentAwardAndPartnerships key={s.slice_type} input={s} />;
            case "privacy_content":
              return <Policies key={s.id} policyData={s} />;
            case "walkin_cards":
              return pageId === "jobs-for-freshers" || pageId ===  "jobs-for-experienced" ? <></> : <WalkinCardList key={s.id} input={s} pagesLinksData={this.linksData} />
            case "overlay_form_display_section":
              return (
                <InlineFormDisplaySection
                  key={s.id}
                  input={s}
                  pagesLinksData={this.linksData}
                  pageId={pageId}
                  emailTemplateData={emailTemplateData}
                  pageOverViewData={this.pageOverViewData}
                  pageHeaderBodyData={this.pageHeaderBodyData}
                  pageHeroData={this.pageHeroData}
                  resourceType={resourceType}
                  pageDisplayName={this.props.pageDisplayName}
                />
              );
          //   case "multi_cards_carousel":
          //     var finalResourceDataObject = {};
          //     finalResourceDataObject.items = this.createTilesObject(this.getCarouselList(s));
          //     return (
          //       <MultiCardsCarousel
          //         cardData={finalResourceDataObject}
          //         pagesLinksData={this.linksData}
          //         selectedFilter={this.selectedFilter}
          //         key="ResourceCards"
          //         type="ResourceCards"
          //         input={s}
          //         pageId={pageId}
          //       />
          //     );
          //   case "cards_with_certifications":
          //     return (<CardsWithCertifications
          //       key={s.id}
          //       input={s}
          //       pageId={pageId}
          //       pagesLinksData={this.linksData}></CardsWithCertifications>)
          //   case "resource_media_cards":
          //     return (
          //       <ResourceMediaCards
          //         key={s.id}
          //         input={s}
          //         pageId={pageId}
          //         pagesLinksData={this.linksData}
          //       />
          //     );
          //     // case "cards_with_certifications":
          //     //   return (<CardsWithCertifications
          //     //     key={s.id}
          //     //     input={s}
          //     //     pageId={pageId}
          //     //     pagesLinksData={this.linksData}></CardsWithCertifications>);
          //   case "resource_tags":
          //      isNewDesignCaseStudy = process.env.NEW_CASE_STUDY_DESIGN && process.env.NEW_CASE_STUDY_DESIGN === 'true'  ? true: false;
          //     if(pageId !== "all-resources" && resourceType !== "Blog" && !isNewDesignCaseStudy) {
          //       this.ResourceTagData = s;
          //       return (
          //         <CallToActionStrip
          //           key={s.id}
          //           input={s}
          //           pagesLinksData={this.linksData}
          //           pageId={pageId}
          //           emailTemplateData={emailTemplateData}
          //           pageOverViewData={this.pageOverViewData}
          //           pageHeaderBodyData={this.pageHeaderBodyData}
          //           pageHeroData={this.pageHeroData}
          //         />
          //       );
          //       }
          //     case "banner-numbers":
          //       return <NumbersBanner key={s.id} input={s} />;
          //     case "testimonials":
          //       return (
          //         <Testimonial
          //           key={s.id}
          //           input={s}
          //           pagesLinksData={this.linksData}
          //         />
          //       );
          //     case "walkthrough":
          //       return (
          //         <WalkThrough
          //           key={s.id}
          //           input={s}
          //           pagesLinksData={this.linksData}
          //         />
          //       );

          //       case "button_with_text":
          //       return (
          //         <ResourceOvierviewButton
          //           key={s.id}
          //           input={s}
          //           pagesLinksData={this.linksData}
          //         />
          //       );

          //     case "resource_overview":
          //       this.resourceOverviewData = s;
          //        isNewDesign =
          //         process.env.NEW_CASE_STUDY_DESIGN &&
          //         process.env.NEW_CASE_STUDY_DESIGN === "true"
          //           ? true
          //           : false;
          //        relatedResources =
          //         resourceType && resourceType === "Blog"
          //           ? this.sortPagesBasedOnREsourceTags(resourceType, 3, 5)
          //           : null;

          //       if (isNewDesign) {
          //         relatedResources =
          //           resourceType && resourceType === "Case Study"
          //             ? this.sortPagesBasedOnREsourceTags(resourceType, 2, 3)
          //             : relatedResources;
          //         relatedResources =
          //           resourceType && resourceType === "White Paper"
          //             ? this.sortPagesBasedOnREsourceTags(
          //                 ["Case Study", "White Paper"],
          //                 2,
          //                 3
          //               )
          //             : relatedResources;
          //       }
          //       return (
          //         <ResourceOverview
          //           pageId={pageId}
          //           key={s.id}
          //           input={s}
          //           author={authorData}
          //           pagesLinksData={this.linksData}
          //           tagsData={resourceTagDetails.items}
          //           tagInput={resourceTagDetails}
          //           relatedBlogs={relatedResources} //reverting new case study design, uncomment line
          //           pageHeroData={this.pageHeroData}
          //         />
          //       );
          //     // case "form_display_section":
          //     //   return <ResourceForms key={s.id} input={s} />;
              case "walkin_detail":
                return <WalkinDetails key={s.id} input={s} />;
              case "page_overview":
                this.pageOverViewData = s;

          //       break;
          //     case "form_display_section":
          //       return (
          //         <FormDisplaySection2
          //           key={s.id}
          //           input={s}
          //           pageId={pageId}
          //           pageOverViewData={this.pageOverViewData}
          //           resourceOverviewData={this.resourceOverviewData}
          //           pageHeroData={this.pageHeroData}
          //           emailTemplateData={emailTemplateData}
          //           resourceType={resourceType}
          //           pageDisplayName={this.props.pageDisplayName}
          //           jobID={this.props.jobID}
          //         />
          //       );
          //     case "award-and-partnerships":
          //       return (
          //         <ContentAwardAndPartnerships key={s.slice_type} input={s} />
          //       );
          //     case "privacy_content":
          //       return <Policies key={s.id} policyData={s} />;
              // case "walkin_cards":
              //   return (
              //     <pageId === "jobs-for-freshers" ? <></> : <WalkinCardsSection
              //       key={s.id}
              //       input={s}
              //       pagesLinksData={this.linksData}
              //     />>
              //   );
          //     case "overlay_form_display_section":
          //       return (
          //         <InlineFormDisplaySection
          //           key={s.id}
          //           input={s}
          //           pagesLinksData={this.linksData}
          //           pageId={pageId}
          //           emailTemplateData={emailTemplateData}
          //           pageOverViewData={this.pageOverViewData}
          //           pageHeaderBodyData={this.pageHeaderBodyData}
          //           pageHeroData={this.pageHeroData}
          //           resourceType={resourceType}
          //           pageDisplayName={this.props.pageDisplayName}
          //         />
          //       );
          //     case "multi_cards_carousel":
          //       var finalResourceDataObject = {};
          //       finalResourceDataObject.items = this.createTilesObject(
          //         this.getCarouselList(s)
          //       );
          //       return (
          //         <MultiCardsCarousel
          //           cardData={finalResourceDataObject}
          //           pagesLinksData={this.linksData}
          //           selectedFilter={this.selectedFilter}
          //           key="ResourceCards"
          //           type="ResourceCards"
          //           input={s}
          //           pageId={pageId}
          //         />
          //       );

          //     case "cards_with_certifications":
          //       return (
          //         <CardsWithCertifications
          //           key={s.id}
          //           input={s}
          //           pageId={pageId}
          //           pagesLinksData={this.linksData}
          //         ></CardsWithCertifications>
          //       );
          //     case "resource_media_cards":
          //       return (
          //         <ResourceMediaCards
          //           key={s.id}
          //           input={s}
          //           pageId={pageId}
          //           pagesLinksData={this.linksData}
          //         />
          //       );
          //     case "cards_with_certifications":
          //       return (
          //         <CardsWithCertifications
          //           key={s.id}
          //           input={s}
          //           pageId={pageId}
          //           pagesLinksData={this.linksData}
          //         ></CardsWithCertifications>
          //       );
          //     case "resource_tags":
          //       let isNewDesignCaseStudy =
          //         process.env.NEW_CASE_STUDY_DESIGN &&
          //         process.env.NEW_CASE_STUDY_DESIGN === "true"
          //           ? true
          //           : false;
          //       if (
          //         pageId !== "all-resources" &&
          //         resourceType !== "Blog" &&
          //         !isNewDesignCaseStudy
          //       ) {
          //         this.ResourceTagData = s;
          //         return (
          //           <ResourceTags
          //             key={s.id}
          //             input={s}
          //             pageId={pageId}
          //             tagsData={s.items}
          //             pagesLinksData={this.linksData}
          //             resourceType={resourceType}
          //           />
          //         );
          //       } else {
          //         this.resourceTagInput = s;
          //       }
          //     case "resource_description":
          //       return (
          //         <ResourceDescription
          //           key={s.id}
          //           input={s}
          //           pageId={pageId}
          //           pagesLinksData={this.linksData}
          //           resourceType={resourceType}
          //         />
          //       );
          //     case "overlay_form_display_section_1":
          //       return (
          //         <InlineButton
          //           key={s.id}
          //           input={s}
          //           pagesLinksData={this.linksData}
          //           pageId={pageId}
          //           pageOverViewData={this.pageOverViewData}
          //           pageHeaderBodyData={this.pageHeaderBodyData}
          //           pageHeroData={this.pageHeroData}
          //           resourceType={resourceType}
          //           pageDisplayName={this.props.pageDisplayName}
          //         />
          //       );
              case "jobs_list_for_walkin":
                this.jobListForWalkin = s;
                break;

          //     case "adobecasestudy":
          //       var finalResourceDataObject = {};
          //       finalResourceDataObject.items = this.createTilesObject(
          //         this.getCarouselList(s)
          //       );
          //       return (
          //         <AdobeSlice
          //           cardData={finalResourceDataObject}
          //           pagesLinksData={this.linksData}
          //           selectedFilter={this.selectedFilter}
          //           key="ResourceCards"
          //           type="ResourceCards"
          //           input={s}
          //           pageId={pageId}
          //         />
          //       );

              case "virtual_walkin_details":
                return (
                  <VirtualWalkinDetails
                    key={s.id}
                    input={s}
                    pageId={pageId}
                    pagesLinksData={this.linksData}
                    jobListForWalkin={this.jobListForWalkin}
                    jobListData={this.props.jobListData}
                  />
                );
              case "brands_work_with":
                return (
                  <BrandsWeWork key={s.id+21}
                  input={s} pagesLinksData={this.linksData}></BrandsWeWork>
                )
            case "job_description":
              let keyDetails = allSlices.filter(slice => ['PrismicJobDescriptionPageBodyKeyDetails'].includes(slice.__typename));
              let Jobtags = allSlices.filter(slice => ['PrismicJobDescriptionPageBodyJobTags'].includes(slice.__typename));
              let primarySkills = allSlices.filter(slice => ['PrismicJobDescriptionPageBodyPrimarySkills'].includes(slice.__typename));
              return (
                <JobDetails
                  key={s.id}
                  input={s}
                  pageId={pageId}
                  pagesLinksData={this.linksData}
                  keyDetails={keyDetails && keyDetails.length > 0 ? keyDetails[0]: null}
                  jobTags={Jobtags && Jobtags.length > 0 ? Jobtags[0] : null}
                  allJobs={this.props.jobListData}
                  primarySkills={primarySkills && primarySkills.length > 0 ? primarySkills[0] : null}
                  pageOverViewData={this.pageOverViewData}
                />
              )
            default:
              return null;
        }
      })
      : null;

    const renderSlices = (pageId === "jobs-for-experienced" || pageId === "jobs-for-freshers") ? <> {extraSlices}{slice}</> : <> {slice}{extraSlices}</>
    

    return (
      <>
        {isWindow && (
          <Suspense
            fallback={<div style={{ height: window.innerHeight }}></div>}
          >
            <div id="slice-container" style={{ position: "relative" }}>
            {slice}
            {extraSlices}
            </div>
          </Suspense>
        )}
      </>
    );
  }
}

SliceZone.propTypes = {
  allSlices: PropTypes.array.isRequired,
  pageId: PropTypes.string,
  homepageEventsData: PropTypes.array,
  resourceTilesData: PropTypes.array,
  homePageFeaturedResourceData: PropTypes.array,
  pagesLinksData: PropTypes.array,
};
