import React, { Component } from "react";
import PropTypes from "prop-types";
import "../styles/WalkinDetails.css";

class WalkinDetails extends Component {
  constructor(props) {
    super(props);
    this.WalkinDetailsData = props.input;
  }
  render() {
    let dateTime = this.WalkinDetailsData.primary.date_and_time.text;
    let dateTimeSplit = dateTime.split("|");
    let id = "";
    if (this.WalkinDetailsData.primary.hasOwnProperty("breadcrumb_id")) {
      id = this.WalkinDetailsData.primary.breadcrumb_id.text;
    }
    
    return (
      <div className="container" id={id}>
        <div className="slice-width-global row">
          <div className="col-12">
            <h1 className="walk-in-event-heading ">
              {this.WalkinDetailsData.primary.walkin_headline.text}
            </h1>
          </div>
          <div className="col-md-4 col-xs-12">
            <p className="date-time">{dateTimeSplit[0]}</p>
            <p className="date-time">{dateTimeSplit[1]}</p>
            {
              this.WalkinDetailsData.primary.interview_venue && (
                <div
                  className="job-venue" 
                  dangerouslySetInnerHTML={{
                    __html: this.WalkinDetailsData.primary.interview_venue ? (this.WalkinDetailsData.primary.interview_venue.raw ? (this.WalkinDetailsData.primary.interview_venue.raw.length > 1 ? this.WalkinDetailsData.primary.interview_venue.html : this.WalkinDetailsData.primary.interview_venue.raw[0].text) : this.WalkinDetailsData.primary.interview_venue.text) : '',
                }}/>
              )
            }
            <h5 className="date-time">
              {this.WalkinDetailsData.primary.contact_title.text}
            </h5>
            {
              this.WalkinDetailsData.primary.contact_person && (
                <div
                  className="contact-details" 
                  dangerouslySetInnerHTML={{
                    __html: this.WalkinDetailsData.primary.contact_person ? (this.WalkinDetailsData.primary.contact_person.raw ? (this.WalkinDetailsData.primary.contact_person.raw.length > 1 ? this.WalkinDetailsData.primary.contact_person.html : this.WalkinDetailsData.primary.contact_person.raw[0].text) : this.WalkinDetailsData.primary.contact_person.text) : '',
                }}>
                </div>
              )
            }
            <a className="add-to-calander" href="#" hrefLang="en">
              {this.WalkinDetailsData.primary.calendar_button_title.text}
            </a>
          </div>
          {
            this.WalkinDetailsData.primary.location_map_url && this.WalkinDetailsData.primary.location_map_url.url && (
              <div className="col-md-8 col-xs-12 location-map-space">
                <iframe
                  src={this.WalkinDetailsData.primary.location_map_url.url}
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allowFullScreen
                  style={{ marginBottom: "0" }}
                />
          </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default WalkinDetails;

WalkinDetails.propTypes = {
  input: PropTypes.object.isRequired
};
