import React, { Component } from "react";
import PropTypes from "prop-types";
import { getColor, mapCounterNumbers } from "../utils/universalHelperFunctions";
import "../styles/numbers-banner.css";
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
// import { Carousel } from "bootstrap";

class NumbersBanner extends Component {
  constructor(props) {
    super(props);
    this.numbersBannerPrimaryData = this.props.input.primary;
    this.numberBannerBackgroundImage = this.props.input.primary.number_background_image.url;
    this.numberBannerItems = mapCounterNumbers(this.props.input.items);
    this.state = {};
  }
  render() {
    let id = "";
    if (this.numbersBannerPrimaryData.hasOwnProperty("breadcrumb_id")) {
      id = this.numbersBannerPrimaryData.breadcrumb_id.text;
    }
    const title = this.numbersBannerPrimaryData.number_banner_heading?.text?.split(' ');

    return (
     <div className="row main-container-number-banner  container" style={{ backgroundImage: `url(${this.numberBannerBackgroundImage})`}}>
      <div className={`col-md-12 sub-container-number-banner home-page-nmbr-bnnr`}>
      <div className="number-banner-content-block col-md-06">
        <div className="number-content-block">
          <div className="heading-nmbr-bnr">
          <span className="heading-container-pws"><span className="heading-container-bold-pws">About </span><span className="heading-container-non-bold-pws">GSPANN</span></span>
          </div>
          <div className="para-1">
            <span>We are a global consulting and IT powerhouse headquartered in Milpitas, California. We elevate businesses across the retail, high-tech, and manufacturing sectors with our top-notch solutions.</span>
          </div>
          <div className="para-2">
            <span>At GSPANN, co-creating is at our essence; we propel the core of the technology. Beyond IT services, we co-create solutions, uniting to break boundaries and take ownership.</span>
          </div>
        </div>
      </div>
      <div className="number-banner-numbers-block col-md-06">
      <div className="number_banner_container" id={id}>
        <div className="container number-banner-sub-container">
          {this.numberBannerItems.map((item, i) => (
              <div key={i} className={`number-banner-item`}>
                <p className="number-banner-item-value">
                  <CountUp
                    end={parseInt(item.value)}
                    redraw={true}
                    suffix={item.suffix}
                    duration={1}>
                    {({ countUpRef, start }) => (
                      <VisibilitySensor onChange={start} delayedCall>
                        <span ref={countUpRef} />
                      </VisibilitySensor>
                    )}
                  </CountUp>
                </p>
                <p className={item.label.length === 24 ? "number_banner_item_key global-cities-class" : "number_banner_item_key"}>{item.label}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      </div>
     </div>

    );
  }
}
export default NumbersBanner;

NumbersBanner.propTypes = {
  input: PropTypes.object
};
