import React, { Component } from "react";
import ReactDOM from 'react-dom';
import PropTypes, { element } from "prop-types";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from "reactstrap";
import { createUrl, getColor } from "../utils/universalHelperFunctions";
import SubHeader from "./SubHeader";
import SubHeaderMobile from "./SubHeaderMobile";
import { SearchFormModal } from "components";
import logo from "./../../static/logos/GSPANN-Logo.svg";
import gspannIcon from "./../../static/logos/GSPANN-Icon.png";
import { FaSearch } from "react-icons/fa";
import "../styles/header.css";

const countryCodeExpression = /loc=([\w]{2})/;

class Header extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.menuData = props.menuData;
    this.logoUrl = props.logoUrl?.url;
    this.linksData = props.pagesLinksData;
    this._element = React.createRef();
    this.toggle = this.toggle.bind(this);
    this.toggleOne = this.toggleOne.bind(this);
    this.displaySearch = this.displaySearch.bind(this);
    this.removeActiveHeader = this.removeActiveHeader.bind(this);
    this.addActiveHeader = this.addActiveHeader.bind(this);
    this.isMenuOpen = this.isMenuOpen.bind(this);
    this.handleDocumentClick = this.handleDocumentClick.bind(this);
    this.onResize = this.onResize.bind(this);

    this.state = {
      navigationBackground: "rgba(255, 255, 255, 1)",
      subMenuItems: {},
      menuDisplay: true,
      searchDisplay: false,
      isOpen: false,
      activeHeader: [],
      isSubMenuOpen: false,
      activeLink: '',
      isMobileView: false,
      countryCode: '',
      currentPath: '',
    };
    // this.leftMenu = [];
    // this.rightMenu = [];
    // props.menuData.forEach(element => {
    //   if(element.primary.nav_item_position === 'left') {
    //     this.leftMenu.push(element);
    //   } else {
    //     this.rightMenu.push(element);
    //   }
    // });   
  }
  componentDidMount() {
    this._isMounted = true;
    if (typeof window !== 'undefined') {
      window.addEventListener("scroll", this.handleScroll.bind(this));
      window.addEventListener('resize', (e) => { this.onResize(); });
      if (window.innerWidth < 768) {
        this.setState({ isMobileView: true });
      }
      this.setState({ cuurentPath: window.location.pathname.split('/')[1] })
      
    }
    fetch("https://www.cloudflare.com/cdn-cgi/trace").then(response => response.text()).
      then(data => { console.log(countryCodeExpression.exec(data)[1]); this.setState({ countryCode: countryCodeExpression.exec(data)[1] }) });
    
    let currentPath = typeof window !== "undefined" ? window.location.pathname.split('/')[1] : "/";
    if(currentPath === "") {
      currentPath = "home";
    }
    const listItem = currentPath && document.querySelector(`#${currentPath}`);
    listItem && listItem.classList.add('active-link');
  }
  componentWillUnmount() {
    if (typeof window !== 'undefined') {
      window.removeEventListener("scroll", this.handleScroll.bind(this));
    }
    this._isMounted = false;
  }
  onResize() {
    if (typeof window !== 'undefined') {
      this.setState({ isMobileView: window.innerWidth < 768 ? true : false });
    }
  }
  handleDocumentClick(e) {
    const container = ReactDOM.findDOMNode(this._element.current);
    if (!document.querySelector('.navigation-toggle-button.navbar-toggler').contains(e.target)) {
      if (this.state.isOpen && e.target !== container && !container.contains(e.target)) {
        this.toggle();
      }
    }
  }
  handleScroll(event) {
    let scrollTop = document.documentElement.scrollTop;
    if (this._isMounted) {
      if (scrollTop <= 360) {
        this.setState({
          navigationBackground: "rgba(255, 255, 255, 1)"
        });
      } else {
        this.setState({
          navigationBackground: "rgba(255, 255, 255, 1)"
        });
      }
    }
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      searchDisplay: false,
      menuDisplay: true,
      activeHeader: []
    }, () => {
      if (this.state.isOpen) {
        document.documentElement.style.overflow = "hidden";
        document.documentElement.addEventListener('click', this.handleDocumentClick, true);
      } else {
        document.documentElement.style.overflow = "auto";
        document.documentElement.removeEventListener('click', this.handleDocumentClick, true);
      }
    });
  }

  toggleOne() {
    console.log('toggleOne called');
    this.setState({
      searchDisplay: !this.state.searchDisplay,
      menuDisplay: false
    });
  }

  displaySubMenu(menu, e) {

    if (menu && menu.items) {
      if (this.state.countryCode === 'IN') {
        this.setState({
          subMenuItems: menu,
          activeLink: menu.primary.label.text
        });
      } else {
        let mainmenu = menu.items.filter(ele => { return ele.sub_nav_link.url !== '/freshers' });

        if (menu.items) {
          menu.items.filter(ele => {
            if (ele.sub_nav_link.url === '/freshers') {
              menu.primary.nav_column_count = 3;
            }
          })
        }


        menu.items = mainmenu;

        this.setState({
          subMenuItems: menu,
          activeLink: menu.primary.label.text
        });
      }



      this.setState({
        collapseSubMenu: this.state.collapseSubMenu === menu ? null : menu
      });
      this.state.activeHeader.find(element => element === menu.primary.label.text) ? this.removeActiveHeader(menu.primary.label.text) : this.addActiveHeader(menu.primary.label.text);
    }
  };

  removeActiveHeader(headerName) {
    let subMenu = [];
    subMenu = this.state.activeHeader.filter(element => {
      return element != headerName;
    });
    this.setState({ activeHeader: subMenu });
  }

  addActiveHeader(headerName) {
    let subMenu = this.state.activeHeader;
    subMenu.push(headerName);
    this.setState({ activeHeader: subMenu });
  }

  hideSubMenu = e => {
    this.setState({
      subMenuItems: {},
      activeLink: ''
    });
  };
  displaySearch = e => {
    this.setState({ menuDisplay: false });
    this.setState({ searchDisplay: true });
  };
  hideSearch = e => {
    this.setState({ menuDisplay: true });
    this.setState({ searchDisplay: false });
  };

  isMenuOpen(menu) {
    var filterData = this.state.activeHeader.filter(element => element === menu.primary.label.text);
    return filterData.length ? true : false;
  }

  handleClick = (e, menu) => {
    if (menu.link) {
      this.displaySubMenu(menu, e);
    } else {
      window.innerWidth < 768 ? this.displaySubMenu(menu, e) : e.preventDefault();
    }
  }
  handleHover = (e, menu) => {
    window.innerWidth >= 768 ? this.displaySubMenu(menu, e) : e.preventDefault();
  }



  render() {
    
    console.log('window:', typeof window);
    return (
      <>
        <div
          className="navigation-container-parent"
        >
          <Navbar light expand="md" className="navigation-container container">
            <div className="header-bar">
              {this.state.menuDisplay && this.state.isMobileView  && (
                <NavbarToggler
                  onClick={this.toggle}
                  className="navigation-toggle-button"
                  style={{ borderColor: "transparent" }}
                >
                  <i className="far fa-bars navbar-toggle"></i>
                </NavbarToggler>
              )

              }
              {this.state.menuDisplay &&
                <a 
                className="header-logo-a-tag"
                // href={this.logoUrl}
                href="https://www.gspann.com/" 
                target="_blank" hrefLang="en"
                onClick={(e) => {
                  e.preventDefault();
                  const sanitizedURL = "https://www.gspann.com/"; // Clean URL
                  window.location.href = sanitizedURL; // Navigate to sanitized URL
                }}
                >
                  {!this.state.isMobileView && <img
                    src={logo}
                    alt="gspann logo"
                    className="navigation-brand-image d-md-block"
                  />}
                  {this.state.isMobileView && <img
                    src={gspannIcon}
                    alt="gspann Icon"
                    className="navigation-brand-image gspann-icon-mobile d-md-block"
                  />}
                </a>
              }
              
              <SearchFormModal openSearchModal={this.state.searchDisplay} hideSearch={this.hideSearch} />
              {!this.state.searchDisplay && <div className="search-icon-mobile" onClick={this.toggleOne} ><FaSearch className="fa-regular fa-magnifying-glass" /></div>}
              {this.state.menuDisplay && !this.state.isMobileView && (
                <NavbarToggler
                  onClick={this.toggle}
                  className="navigation-toggle-button"
                  style={{ borderColor: "transparent" }}
                >
                  <i className="far fa-bars navbar-toggle"></i>
                </NavbarToggler>
              )}
            </div>
            <Collapse
              isOpen={this.state.isOpen}
              navbar
              className="navigation-collapse-section"
              ref={this._element}
            >
              {this.state.menuDisplay && (
                <Nav
                  className="header-nav"
                  navbar
                  onMouseLeave={e => this.setState({ subMenuItems: {}, activeLink: '' })}
                >
                  <li className="left-nav-section" style={{ margin: '0px', width: '100%' }}>
                    <ul className='list-partition'>
                      {this.props.menuData.map((menu, i) => {                        
                        const currentPath = typeof window !== "undefined" ? window.location.pathname.split('/')[1] : "";
                        return (
                          <NavItem
                            id={menu.link?.url.split('/')[1] || 'home'}
                            className={'navigation-item'}
                            key={menu.link_label?.text}
                            onMouseOver={e => this.handleHover(e, menu)}
                          >
                            <NavLink
                              className={`navigation-link`}
                              href={
                                menu.link && menu.link?.url
                              }
                              title={menu.link_label?.text}
                              onClick={e => { if (!menu.link) e.preventDefault(); }}
                            >
                              {menu.link_label?.text}
                              <div className={`${this.isMenuOpen(menu) ? "d-inline" : "d-none"} up-down-arrow`}>
                                <i className={`d-inline-block d-md-none fal fa-chevron-up`}></i>
                              </div>
                              <div className={`${this.isMenuOpen(menu) ? "d-none" : "d-inline"} up-down-arrow`}>
                                <i className={`d-inline-block d-md-none fal fa-chevron-down`}></i>
                              </div>
                            </NavLink>
                            {
                              menu.items && menu.items.length > 0 && <Collapse
                                isOpen={this.isMenuOpen(menu)}
                                className="sub-header-collapse"
                                style={{ background: menu.primary?.nav_item_background_color ? getColor(menu.primary?.nav_item_background_color) : '#1C355E' }}
                              >
                              </Collapse>
                            }
                          </NavItem>
                        )
                      })}
                    </ul>
                  </li>
                </Nav>
              )}
            </Collapse>
            {!this.state.searchDisplay && (
              <div className="nav-custom" id="search-360-container" onClick={this.displaySearch}>
                <FaSearch className="fa-regular fa-magnifying-glass searchBar-iconStyle" id="globalSearch" />
              </div>
            )}
          </Navbar>
        </div>
      </>
    );
  }
}

export default Header;

Header.propTypes = {
  menuData: PropTypes.array.isRequired,
  pagesLinksData: PropTypes.array
};
