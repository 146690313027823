import React, { useState, useEffect } from "react";
import { getColor } from "../utils/universalHelperFunctions";
import '../styles/information-card-component.css';

function InformationCardComponent(props) {
  const [cultureFlag, setCultureFlag] = useState('US');

  useEffect(() => {
    // fetch("https://jsonip.com", {mode: 'cors'})
    fetch("https://api.ipstack.com/check?access_key=c04f9cf5cdbfa908261a073d0d6b3265", { mode: 'cors' })
    .then(responseIPDetials => responseIPDetials.json())
    .then(IPDetails => fetch(`https://api.ipstack.com/${IPDetails.ip}?access_key=c04f9cf5cdbfa908261a073d0d6b3265`, {mode: 'cors'}))
    .then(responseCountryCode => responseCountryCode.json())
    .then(countryCode => setCultureFlag(countryCode.country_code));
  }, []);

  let cardButtonLink = '';
  if( props.infoCardData.card_button_link && props.infoCardData.card_button_link.url ) {
    cardButtonLink = props.infoCardData.card_button_link.url;
  } else {
    cardButtonLink = null;
  }
  if (props.infoCardData.card_button_us_link && props.infoCardData.card_button_us_link.url && cultureFlag === "US") {
    cardButtonLink = props.infoCardData.card_button_us_link.url;
  }
  
  return (
    <div className="col-12 col-sm-6 col-md-6 col-lg-4 info-card-container">
      <div className='col-12 info-card' style={{ backgroundColor: `${props.infoCardData.card_background_color ? getColor(props.infoCardData.card_background_color) : '#fff'}` }}>
        {
          props.infoCardData.icon_class && props.infoCardData.icon_class.text && (
            <i className={`tile-icon ${props.infoCardData.icon_class.text}`} style={{ color: props.infoCardData.icon_color ? getColor(props.infoCardData.icon_color) : '#000' }}></i>
          )
        }
        <h3 className={`info-card-heading`}>
          {props.infoCardData.card_heading.text}
        </h3>
        <p className="info-card-description">
          {props.infoCardData.card_description.text}
        </p>
        {
          props.infoCardData.card_button_link && props.infoCardData.card_button_link.url && (
            <a href={props.infoCardData.card_button_link.url} style={{ color: props.infoCardData.card_button_color ? getColor(props.infoCardData.card_button_color) : '#000' }} className="info-card-button" hrefLang="en">
              {props.infoCardData.card_button_text.text}
            </a>
          )
        }
      </div>
    </div>
  );
}

export default InformationCardComponent;
